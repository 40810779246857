import Cookies from 'js-cookie';

import { storage } from 'utils';

export const logout = (cb) => {
  storage.clear();
  Cookies.remove('_token');
  Cookies.remove('_user_key');
  Cookies.remove('_galanta_free_trial_reminder');
  cb();
};
