import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/order
import { useFormik } from 'formik';
import classNames from 'classnames';
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5'

// import {useNavigate} from 'react-router-dom';

import { FormInput } from 'ui/forms';
import { Button } from 'ui/components';
import { toastSuccess, toastWarning } from 'ui/toast';

import { useOtpConfirm, useSignUp } from '../_hooks';
import { otpConfirmSchema, signUpSchema } from '../_validations';
import { INIT_OTP_CONFIRM_FORM, INIT_REGISTER_FORM } from '../_constants';

function RegisterForm() {
  // const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [registerValidation, setRegusterValidation] = useState(false);
  const [otpValidation, setOtpValidation] = useState(false);
  const [isApiLoading, signup] = useSignUp();
  const [isVerifyingOtp, verifyOtp] = useOtpConfirm();
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordHasUpperCase, setPasswordHasUpperCase] = useState(false);
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState(false);

  // const [isTimeZoneLoading, timeZone] = useDataSets('TIME_ZONE', {});

  const {
    handleSubmit: handleRegister,
    setFieldValue,
    values,
    errors,
  } = useFormik({
    initialValues: INIT_REGISTER_FORM,
    validationSchema: signUpSchema,
    validateOnChange: registerValidation,
    validateOnBlur: registerValidation,
    onSubmit: (form) => {
      signup(form, () => {
        setStep(2);
      });
    },
  });

  const {
    handleSubmit: handleOtpConfirmation,
    setFieldValue: setOtpFieldValue,
    values: step2Values,
    errors: step2Errors,
  } = useFormik({
    initialValues: INIT_OTP_CONFIRM_FORM,
    validationSchema: otpConfirmSchema,
    validateOnChange: otpValidation,
    validateOnBlur: otpValidation,
    onSubmit: (form) => {
      verifyOtp(
        { ...form, email: values.email },
        () => {
          toastSuccess(`One-time code verified successfully.`);
          setStep(3);
        },
        (e) => {
          // eslint-disable-next-line no-console
          // console.log(e);
          toastWarning(e.message);
        }
      );
    },
  });

  useEffect(() => {
    setPasswordLengthValid(values.password.length >= 8);
    setPasswordHasUpperCase(/[A-Z]+/.test(values.password));
    setPasswordHasSpecialChar(/[^A-Za-z0-9]+/.test(values.password));
  }, [values.password]);

  return (
    <>
      {step === 1 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRegister();
            setRegusterValidation(true);
          }}
          noValidate
          autoComplete="off"
        >
          <h4 className="text-lg lg:text-xl 2xl:text-3xl font-bold">
            Create an Account
          </h4>
          <div className="mt-5 space-y-7">
            <div className="space-y-4">
              <FormInput
                id="signup_store_name"
                name="store_name"
                label="Store Name"
                autoFocus
                noautofill="true"
                value={values?.store_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.store_name}
                required
                placeholder="Your Store Name"
              />
              <FormInput
                id="signup_first_name"
                name="first_name"
                label="First Name"
                noautofill="true"
                value={values?.first_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.first_name}
                placeholder="John"
                required
              />
              <FormInput
                id="signup_last_name"
                name="last_name"
                label="Last Name"
                noautofill="true"
                placeholder="Doe"
                value={values?.last_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.last_name}
                required
              />
              <FormInput
                id="signup_email"
                name="email"
                label="Email"
                type="email"
                placeholder="Ex. your@email.com"
                noautofill="true"
                value={values?.email || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.email}
                required
              />
              <FormInput
                id="signup_password"
                label="Password"
                name="password"
                placeholder="Must be at least 8 characters"
                value={values?.password || ''}
                type="password"
                autoComplete="none"
                onSetFieldValue={setFieldValue}
                withShowPassword
                error={errors?.password}
                required
              />
              {!!values?.password && (
                <div>
                  <div
                    className={classNames(
                      'font-medium sm flex w-full flex-row items-center',
                      {
                        'text-green-500': passwordLengthValid,
                        'text-red-500': !passwordLengthValid,
                      }
                    )}
                  >
                    {passwordHasUpperCase ? <FaCheck /> : <IoCloseSharp />}
                    At least 8 characters
                  </div>
                  <div
                    className={classNames(
                      'font-medium sm flex w-full flex-row items-center',
                      {
                        'text-green-500': passwordHasUpperCase,
                        'text-red-500': !passwordHasUpperCase,
                      }
                    )}
                  >
                    {passwordHasUpperCase ? <FaCheck /> : <IoCloseSharp />}
                    Must contain at least 1 uppercase letter
                  </div>
                  <div
                    className={classNames(
                      'font-medium sm flex w-full flex-row items-center',
                      {
                        'text-green-500': passwordHasSpecialChar,
                        'text-red-500': !passwordHasSpecialChar,
                      }
                    )}
                  >
                    {passwordHasUpperCase ? <FaCheck /> : <IoCloseSharp />}
                    Must have at least one special character
                  </div>
                </div>
              )}
              <FormInput
                id="signup_password_confirmation"
                label="Password Confirmation"
                name="password_confirmation"
                placeholder="Confirm your password"
                value={values?.password_confirmation || ''}
                type="password"
                autoComplete="none"
                onSetFieldValue={setFieldValue}
                withShowPassword
                error={errors?.password_confirmation}
                required
              />
            </div>
            <Button
              withLoader
              className="w-full py-3"
              id="submit"
              type="submit"
              label="Submit"
              color="primary"
              disabled={isApiLoading}
              isLoading={isApiLoading}
            />
          </div>
        </form>
      )}
      {step === 2 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleOtpConfirmation();
            setOtpValidation(true);
          }}
          noValidate
          autoComplete="off"
        >
          <h4 className="text-lg lg:text-xl 2xl:text-3xl font-bold">
            Please check your email for One-Time Code:
          </h4>
          <div className="mt-5 space-y-7">
            <div className="space-y-4">
              <FormInput
                id="otp"
                name="otp"
                label="One-time Code"
                noautofill="true"
                value={step2Values?.otp || ''}
                onSetFieldValue={setOtpFieldValue}
                error={step2Errors?.otp}
                required
                placeholder="One-time Code"
              />
            </div>
            <Button
              withLoader
              className="w-full py-3"
              id="submit"
              type="submit"
              label="Submit"
              color="primary"
              disabled={isVerifyingOtp}
              isLoading={isVerifyingOtp}
            />
          </div>
        </form>
      )}
      {step === 3 && (
        <h4 className="text-base 2xl:text-xl">
          <p>
            Thank! Your account has been verified successfully! Please login
            with your account from the left side.
          </p>
        </h4>
      )}
    </>
  );
}

RegisterForm.defaultProps = {};

RegisterForm.propTypes = {};

export default RegisterForm;
