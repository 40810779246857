import React from 'react';

import { Route, Routes as Switch } from 'react-router-dom';

import ToastMarker from 'ui/toast';
import { DrawerMarker } from 'ui/drawer';
import { AlertMarker, ModalMarker } from 'ui/modals';
import MessageBox from 'features/message-box';
import PageSuspense from 'layouts/page-suspense';
import ShopifyLandingPage from "features/shopify-landing-page";
// import CookieConsentNotification from 'features/cookie-consent-notification';

function App() {
  return (
    <>
      <ToastMarker />
      <AlertMarker />
      <ModalMarker />
      <DrawerMarker />
      {/* <CookieConsentNotification /> */}
      <Switch>
        <Route path="*" element={<PageSuspense />} />
        <Route path="/message" element={<MessageBox />} />
        <Route path="/shopify-landing-page" element={<ShopifyLandingPage />} />
      </Switch>
    </>
  );
}

export default App;
