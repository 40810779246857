import React from 'react';

import {
  MdCropPortrait,
  MdCropLandscape,
  MdOutlineCropFree,
  MdOutlineCropSquare,
} from 'react-icons/md';
import cn from 'classnames';
import { Cropper, ImageRestriction } from 'react-advanced-cropper';

import { submitFile } from '../_actions';
import { ROOT_ACTION } from '../_constants';
import CloseButton from '../components/CloseButton';
import SectionTitle from '../components/SectionTitle';

const aspectRatioClass =
  'font-semibold text-sm text-gray-400 px-4 py-1 transition duration-300 ease-in-out';

const ratioOption = {
  1: {
    icon: MdOutlineCropFree,
    label: 'Free',
    size: 0,
  },
  2: {
    icon: MdCropPortrait,
    label: 'Portrait',
    size: 6 / 9,
  },
  3: {
    icon: MdOutlineCropSquare,
    label: 'Square',
    size: 1 / 1,
  },
  4: {
    icon: MdCropLandscape,
    label: 'Landscape',
    size: 16 / 8,
  },
};

const defaultSize = ({ imageSize, visibleArea }) => ({
  width: (visibleArea || imageSize).width,
  height: (visibleArea || imageSize).height,
});

function CropperSection() {
  const cropperRef = React.useRef();

  const [ratio, setRatio] = React.useState(1);
  const { onClose, file, options } = React.useContext(ROOT_ACTION);

  const handleOnUpload = async (e) => {
    e.preventDefault();
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      await canvas.toBlob(async (blob) => {
        if (blob) {
          const args = new File([blob], file?.file_name, { type: blob.type });
          submitFile(args, options);
        }
      });
    }
    onClose();
  };

  const handleOnChangeRatio = (v) => (e) => {
    e.preventDefault();
    setRatio(v);
  };

  return (
    <>
      <div className="flex items-center">
        <SectionTitle title="Crop and add this image" />
        <CloseButton />
      </div>
      <div className="w-full h-full flex flex-col flex-1 space-y-3">
        <Cropper
          ref={cropperRef}
          src={file && file.src}
          className="w-[760px] h-[578px] shadow-inner"
          stencilProps={{
            aspectRatio: ratioOption[ratio]?.size,
            grid: true,
            movable: true,
            resizable: true,
          }}
          defaultSize={defaultSize}
          imageRestriction={ImageRestriction.fitArea}
        />
        <div className="flex-1 flex justify-between items-center">
          <button
            type="button"
            className="rounded w-28 py-2 text-gray-700 border border-bg-gray-700 hover:bg-gray-600 hover:text-white transition duration-200 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <div className="space-x-2">
            {Object.keys(ratioOption)?.map((i) => {
              const x = ratioOption[i];
              const active = +i === +ratio;

              return (
                <button
                  type="button"
                  key={i}
                  onClick={handleOnChangeRatio(i)}
                  className={cn(aspectRatioClass, {
                    'text-gray-800': active,
                    'hover:text-gray-800': !active,
                  })}
                >
                  <x.icon className="h-6 w-6 mx-auto" />
                  <div>{x?.label}</div>
                </button>
              );
            })}
          </div>
          <button
            type="button"
            className="rounded w-28 py-2 text-white bg-gray-700 hover:bg-gray-500 transition duration-200 ease-in-out"
            onClick={handleOnUpload}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}
CropperSection.defaultProps = {};

CropperSection.propTypes = {};

export default CropperSection;
