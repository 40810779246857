import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import find from 'lodash/find';
import Cookies from 'js-cookie';

const ImportModuleErrors = [
   "Failed to fetch dynamically imported module",
   "Failed to load module script"
];
const expirationTime = 5 * 60 * 1000; // 5 minutes

const isImportModuleError = (error) => find(ImportModuleErrors, msg => error.message.indexOf(msg) !== -1);

class ErrorBoundryWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo); // eslint-disable-line
    Sentry.captureException(error, errorInfo);
    if (isImportModuleError(error)) {
      const lastRefreshTime = Cookies.get("_galanta_last_refresh_time");
      const now = new Date().getTime();
      const isTimePassed = lastRefreshTime && ((now - parseInt(lastRefreshTime, 10)) > expirationTime);

      if (!lastRefreshTime || isTimePassed) {
        Cookies.set(
          '_galanta_last_refresh_time',
          now.toString(),
          expirationTime
        );
        window.location.reload();
      }
    }
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="fixed bg-white z-50 inset-0 w-screen h-screen bg-opacity-100">
          <div className="w-full h-full bg-white bg-opacity-100">
            <div className="flex flex-col justify-center items-center h-full gap-12">
              <div className="text-center space-y-3">
                <div className="text-2xl font-bold">Internal Server Error</div>
                <div className="font-light text-sm">
                  Sorry, something went wrong in our end
                </div>

                <Link to="/">
                  <button className="btn primary md !mt-5 px-5" type="button">
                    Go to homepage
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }

}
ErrorBoundryWrapper.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ErrorBoundryWrapper;
