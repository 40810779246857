import * as yup from 'yup';

export const signUpSchema = yup.object({
  // tz: yup.object().required('Timezone is required.').nullable(),
  email: yup.string().required('Email is required.').email(),
  password: yup
    .string()
    .min(8, 'At least 8 characters')
    .required('Password is required.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required('Password confirmation is required.'),
  store_name: yup.string().required('Store name is required.'),
  first_name: yup.string().required('First name is required.'),
});

export const loginSchema = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('email is required.'),
  password: yup.string().required('Password is required.'),
});

export const otpConfirmSchema = yup.object({
  otp: yup.string().required('One Time Code is required.'),
});
