import React from 'react';

import CloseButton from '../components/CloseButton';
import { ROOT_ACTION } from '../_constants';

function UploadFileSection() {
  const { setFile, setSeleted } = React.useContext(ROOT_ACTION);

  const inputRef = React.useRef();
  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onLoadImage = async (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);
      const name = files[0]?.name?.replaceAll(' ', '_');

      await setFile({
        src: blob,
        type: files[0]?.type,
        file_name: name,
        file_size: files[0]?.size,
        original_file: files[0],
      });
      setSeleted('preview');
    }
    e.target.value = '';
  };

  return (
    <>
      <div className="flex items-center">
        <CloseButton />
      </div>
      <div className="flex flex-1">
        <button
          className="bg-gray-700 m-auto text-lg rounded px-4 py-2 text-white hover:bg-gray-600 transition ease-in-out duration-300"
          onClick={onUpload}
          type="button"
        >
          <input
            ref={inputRef}
            type="file"
            className="hidden"
            onChange={onLoadImage}
          />
          Choose a file local
        </button>
      </div>
    </>
  );
}
UploadFileSection.defaultProps = {};

UploadFileSection.propTypes = {};

export default UploadFileSection;
