import { req } from 'services';
import { transformUser } from 'features/authentication/_transformers';

import { transformPayload } from './_transformers';

export const signUp = ({ payload }) =>
  req.post({
    url: `/merchants/register`,
    payload: transformPayload(payload),
  });

export const login = ({ type, payload }) =>
  req.post({
    url: '/merchants/auth',
    payload,
    transform: (res) => {
      const x = transformUser(res?.data, type);
      return x;
    },
  });

export const verifyOtpCode = ({ payload }) =>
  req.post({
    url: `/merchants/verify-otp`,
    payload,
    transform: (res) => 
      // eslint-disable-next-line no-console
      // console.log('otp response', res);
       res.data
    ,
  });
