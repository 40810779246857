import React from 'react';

import { createRoot } from 'react-dom/client';

import UploaderModal from './modals/UploaderModal';

function OpenDialog(options) {
  const opt = options;
  document.body.className = 'overflow-hidden';

  const divContainer = document.createElement('div');
  divContainer.id = 'uploader-widget';
  document.body.appendChild(divContainer);

  const container = document.getElementById('uploader-widget');

  const root = createRoot(container);

  const prevElem = document.activeElement;

  if (!root) {
    alert('Toast root not found!'); // eslint-disable-line
    return null;
  }

  const onClose = () => {
    try {
      setTimeout(() => {
        document.body.removeAttribute('class');
        container.remove();
        setTimeout(() => {
          try {
            prevElem.blur();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'Modal Uploader Error!!');
          }
        }, 100);
      }, 200);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, ' here');
    }
  };

  return root.render(<UploaderModal onClose={onClose} options={opt} />);
}

export default OpenDialog;
